@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: 'Inter', sans-serif;
    scroll-behavior: smooth;
  }

  body::-webkit-scrollbar,
  body ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  body::-webkit-scrollbar-track,
  body ::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: none;
  }

  body::-webkit-scrollbar-thumb,
  body ::-webkit-scrollbar-thumb {
    background-color: #DBDBDE;
    border-radius: 10px;
    box-shadow: none;
  }

  body::-webkit-scrollbar-thumb:window-inactive,
  body ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(66, 66, 66, 0.4);
  }
  .navbarbg {
    background-color: #7e22ce;
  } 
}
